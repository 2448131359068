import React, { useState } from "react";
import { PageContainer } from "../container";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  BUTTON_TYPE,
  // Checkbox,
  Password,
  Form,
  Field,
  showError,
} from "../../shared/widgets";
import { UserService } from "../../shared/service";
import { useAccountStore } from "../../shared/store";
import { getItem, setItem } from "../../shared/utils";
import { TABLES } from "../../shared/constants/tables";

import styles from "./resetpassword.module.css";

export function ResetPasswordPage() {
  let history = useHistory();
  let params = useParams();

  const [showPassword, setShowPassword] = useState(false);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    let data = {
      password: values.password,
      key: params.key,
      user_id: params.userId,
    };
    useAccountStore.getState().setLoading(true);
    const userId = getItem(TABLES.USER_ID);
    UserService.updateUserPassword(userId, values)
      .then((response) => {
        console.log(response, params);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        // passwordForm.resetFields();
        // setRestorePassword(false);
        useAccountStore.getState().setLoading(false);
        history("/");
      });
  };

  return (
    <PageContainer>
      <Form
        name="normal_login"
        className={styles.resetPasswordForm}
        initialValues={{
          showPassword: showPassword,
        }}
        // onFieldsChange={onFieldsChange}
        onFinish={onFinish}
      >
        <Field>
          <div className={styles.headerContent}>
            <div className="header600">Reset your password</div>
            <div className="tiny100">
              Ensure your new password's character length is in between 8 - 16.
              It should contain atleast one number (0-9) and one charater (a-z).
            </div>
          </div>
          <div className="header600"></div>
        </Field>
        <Field
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              min: 8,
              message: "Should be minimum of 8 characters",
            },
            {
              max: 100,
              message: "Should not be more than 100 characters",
            },
          ]}
          hasFeedback
        >
          <Password placeholder="Password" />
        </Field>
        <Field
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Password placeholder="Confirm password" />
        </Field>
        <Field>
          <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
            Reset password
          </Button>
        </Field>
      </Form>
    </PageContainer>
  );
}
