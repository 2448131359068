import React from "react";
import { Form as AntForm } from "antd";
// import "antd/es/form/style/css";
import styles from "./form.module.css";

export function Form({ ...props }) {
  return <AntForm {...props} />;
}

export function Field({ ...props }) {
  return <AntForm.Item {...props} />;
}

export function FieldList({ ...props }) {
  return <AntForm.List {...props} />;
}

export function ErrorList({ ...props }) {
  return <AntForm.ErrorList {...props} />;
}
export const useForm = AntForm.useForm;

export const useWatch = AntForm.useWatch;
