import { post, get, put, deleteReq } from "./public.service";

export const PublicFormService = {
    getSchema(account_id, flow_id) {
        return get(`/${account_id}/public/metadatastore/live/${flow_id}`);
    },
    getFormData(account_id, flow_id, item_id, quotation_id) {
        return get(`/${account_id}/public/procurement/${flow_id}/${item_id}/quotation/${quotation_id}`);
    },
    saveFormData(account_id, flow_id, item_id, quotation_id, data) {
        return post(`/${account_id}/public/procurement/${flow_id}/${item_id}/quotation/${quotation_id}`, { body: data });
    },
    submitForm(account_id, flow_id, item_id, quotation_id, data) {
        return put(`/${account_id}/public/procurement/${flow_id}/${item_id}/quotation/${quotation_id}/submit`, { body: {} });
    }
};
