import React from 'react';
import { List as AntList } from 'antd';
// import "antd/es/list/style/css";
// import styles from './button.module.css';
const { Item } = AntList;

export function List({
    ...props
}) {
  return (
    <AntList {...props} />
  );
}

export { Item as ListItem}