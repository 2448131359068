import React from "react";
import { Dropdown as AntDropdown } from "antd";
// import 'antd/es/dropdown/style/css';
// import styles from "./dropdown.module.css";

export function Dropdown({ children, onSelect, ...props }) {
  const onUpdate = (value) => {
    onSelect(value);
  };
  return (
    <AntDropdown onChange={(value) => onUpdate(value)} {...props}>
      {children}
    </AntDropdown>
  );
}
