function setObject(key, value) {
  localStorage?.setItem?.(key, JSON.stringify(value));
}

function getObject(key) {
  const ret = localStorage?.getItem?.(key);
  return JSON.parse(ret);
}

function setItem(key, value) {
  localStorage?.setItem?.(key, value);
}

function getItem(key) {
  const value = localStorage?.getItem?.(key);
  return value;
}

function removeItem(key) {
  localStorage?.removeItem?.(key);
}

function keys() {
  const keys = localStorage?.keys?.();
  console.info("Got keys: ", keys);
}

function clear() {
  localStorage?.clear?.();
}

export { clear, keys, removeItem, getItem, setItem, getObject, setObject };
