import React, { useState } from "react";
import { PageContainer } from "../container";
import { useHistory } from "react-router-dom";
import { UserService } from "../../shared/service";
import { useAccountStore } from "../../shared/store";

import {
  Button,
  BUTTON_TYPE,
  Form,
  Field,
  Input,
  Password,
  showError,
} from "../../shared/widgets";
import { Link } from "react-router-dom";
import styles from "./signup.module.css";

export function SignupPage() {
  const [sucess, setSucess] = useState(false);

  const onFinish = (values) => {
    useAccountStore.getState().setLoading(true);
    UserService.signUp(values)
      .then(() => {
        setSucess(true);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        useAccountStore.getState().setLoading(false);
      });
  };

  return (
    <PageContainer>
      {!sucess ? (
        <Form
          name="signup_form"
          className={styles.signupForm}
          initialValues={{}}
          onFinish={onFinish}
        >
          <Field>
            <div className={styles.headerContent}>
              <div className="header600">Signup</div>
              <div className="tiny100">
                Please set a new 8-16 character password and also your password
                must contain at least one letter (a-Z), one number (0-9)
              </div>
            </div>
            <div className="header600"></div>
          </Field>
          <Field
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name here",
              },
            ]}
            className={"flexField"}
          >
            <Input
              // prefix={<UserOutlined className="site-form-item-icon" />}
              type={"text"}
              placeholder="Name"
            />
          </Field>
          <Field
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email here",
              },
            ]}
            className={"flexField"}
          >
            <Input
              // prefix={<UserOutlined className="site-form-item-icon" />}
              type={"email"}
              placeholder="Email"
            />
          </Field>
          <Field
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                pattern: RegExp(
                  "^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()-__+.]){1,}).{8,}$"
                ),
                message: "Please provide a strong password",
              },
              {
                warningOnly: false,
              },
            ]}
            className={`flexField ${styles.passwordRow}`}
          >
            <Password
              // prefix={<LockOutlined className="site-form-item-icon" />}
              type={"password"}
              placeholder="Password"
            />
          </Field>
          <Field>
            <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
              Sign up
            </Button>
          </Field>
          <Field className="center">
            <Link to="/login">Back to sign in</Link>
          </Field>
        </Form>
      ) : (
        <div className={`${styles.signupSccuess} center`}>
          <div className="header600">Signup success</div>
          <div className="tiny100">
            Kindly check your mail to complete signup process
          </div>
          <Link to="/login" className="tiny100">
            Back to sign in
          </Link>
        </div>
      )}
    </PageContainer>
  );
}
