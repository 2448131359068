import { post, get } from "./service";

export const WorkflowService = {
  getDraftMetadata(table_id = "purchase_order") {
    return get(`/metadatastore/draft/${table_id}`);
  },
  saveDraftMetadata(table_id = "purchase_order", data) {
    return post(`/metadatastore/draft/${table_id}`, { body: data }, true);
  },
  getLiveMetadata(table_id = "purchase_order") {
    return get(`/metadatastore/live/${table_id}`);
  },
  saveLiveMetadata(table_id = "purchase_order", data) {
    return post(`/metadatastore/live/${table_id}`, { body: data }, true);
  },
};
