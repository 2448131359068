import React from "react";
import { debounce } from "lodash";
import { Button, Input, BUTTON_TYPE, Tooltip } from "../../widgets";
import styles from "./styles.module.css";

export function GridHeader({
  headerText,
  onSearch,
  onCreate,
  createButtonContent,
  children,
  disableText,
}) {
  const onDebounceSearch = debounce((e) => onSearch(e), 300);

  return (
    <div className={styles.header}>
      {children ? (
        <span>{children}</span>
      ) : (
        <span className="h500">{headerText}</span>
      )}
      <div className={styles.action}>
        {onSearch ? (
          <div>
            <Input
              placeholder={`search`}
              onChange={(e) => onDebounceSearch(e?.target?.value)}
              allowClear
            />
          </div>
        ) : (
          ""
        )}
        {onCreate ? (
          <>
            {createButtonContent && (
              <Button
                type={BUTTON_TYPE.PRIMARY}
                onClick={onCreate}
              >
                <span className={styles.createButton}>
                  {createButtonContent()}
                </span>
              </Button>
            )}
          </>
        ) : (
          <>
            {createButtonContent && (
              <Tooltip title={disableText}>
                <div className={styles.disabledButton}>
                  <Button
                    type={BUTTON_TYPE.PRIMARY}
                    className={styles.createButton}
                    disabled={true}
                  >
                    <span className={styles.createButton}>
                      {createButtonContent()}
                    </span>
                  </Button>
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
}
