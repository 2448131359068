import React from 'react';
import { Pagination as AntPagination } from 'antd';
// import 'antd/es/pagination/style/css'; 
// import styles from './button.module.css';

export function Pagination({
    children,
    ...props
}) {
  return (
    <AntPagination {...props}>
        {children}
    </AntPagination>
  );
}
