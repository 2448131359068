import React, { useEffect } from "react";
import { PageContainer } from "../container";
import { Link, useHistory } from "react-router-dom";
import { UserService } from "../../shared/service";
import { TABLES } from "../../shared/constants/tables";
import { getItem, setItem, clear } from "../../shared/utils";

import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  showError,
  Password,
} from "../../shared/widgets";
import { useAccountStore } from "../../shared/store";
import styles from "./login.module.css";

export function LoginPage() {
  let history = useHistory();

  useEffect(() => {
    if (getItem(TABLES.ACCOUNT_ID)) {
      onLoginSucess();
    }
  }, []);

  const onFinish = (values) => {
    clear();
    useAccountStore.getState().setLoading(true);

    // --------------------------------------
    // DUMMY CODE TO LOGIN USERS, NOT USING DATABASE
    // let users = getObject(TABLES.USERS);
    // let user = null;

    // users.forEach((item) => {
    //   if (item.email === values.email) {
    //     user = item;
    //   }
    // });

    // if (user.password === values.password) {
    //   setItem(TABLES.TOKEN, user.email);
    //   setObject(TABLES.CURRENT_USER, user);
    //   onLoginSucess();
    // } else {
    //   showError("incorrect mail or password");
    // }

    // useAccountStore.getState().setLoading(false);
    // return;
    // -----------------------------------------
    UserService.signIn(values)
      .then((data) => {
        // useAccountStore
        //   .getState()
        //   .updateAccountDetails({ account_id: data.account_id });
        setItem(TABLES.ACCOUNT_ID, data.account_id); // Temp fix for "account_id removed" bug
        setItem(TABLES.USER_ID, data.user_id);
        onLoginSucess();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        useAccountStore.getState().setLoading(false);
      });
  };

  const onLoginSucess = () => {
    history.push(`/home`);
  };

  return (
    <PageContainer>
      <Form
        name="normal_login"
        className={styles.loginForm}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Field>
          <div className={styles.header}>
            <div className="header600 center">Welcome Back</div>
            <div className="tiny100 center">Sign in to your account</div>
          </div>
        </Field>
        <Field
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
          className={"flexField"}
        >
          <Input placeholder="Email" type={"email"} />
        </Field>
        <Field
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
          className={"flexField"}
        >
          <Password type="password" placeholder="Password" />
        </Field>
        <Field className={styles.forgotPasswordRow}>
          <Link className={styles.forgotPassword} to="/forgotpassword">
            Forgot password
          </Link>
        </Field>
        <Field>
          <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
            Sign in
          </Button>
        </Field>
        <Field>
          <div className={styles.singUp}>
            <span className="text400">Don't have an account?</span>
            <Link className={styles.signUpLink} to="/signup">
              Sign Up
            </Link>
          </div>
        </Field>
      </Form>
    </PageContainer>
  );
}
