import React from "react";
import { AutoComplete as AntAutoComplete } from "antd";
// import "antd/es/auto-complete/style/css";
// import styles from './button.module.css';

export function AutoComplete({ ...props }) {
  return <AntAutoComplete {...props} />;
}

const { Option } = AntAutoComplete;
export { Option as AutoCompleteOption };
