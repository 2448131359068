import React from "react";
import { Select as AntSelect } from "antd";
// import "antd/es/select/style/css";
import styles from "./styles.module.css";

export function Select({ children, onSelect = () => {}, ...props }) {
  // const selectRef = useRef(null);

  // const onUpdate = (value) => {
  //   console.log("chk heeeere");
  //   selectRef.current.blur();
  //   onSelect(value);
  // };

  return (
    <AntSelect onSelect={onSelect} {...props}>
      {children}
    </AntSelect>
  );
}

const { Option } = AntSelect;

export { Option as SelectItem };
