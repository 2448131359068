import { getObject, setObject } from "../utils";

import { TABLES } from "../constants/tables";

const SUPER_USERS = [
  {
    name: "Ram",
    account_status: "active",
    email: "ram@enegma.in",
    password: "12345678",
    role: "super_admin",
  },
  {
    name: "Prashanth",
    account_status: "active",
    email: "prashanth@enegma.in",
    password: "12345678",
    role: "super_admin",
  },
  {
    name: "Sammer",
    account_status: "active",
    email: "sammer@enegma.in",
    password: "12345678",
    role: "super_admin",
  },
  {
    name: "Rishabh",
    account_status: "active",
    email: "rishabh@enegma.in",
    password: "12345678",
    role: "super_admin",
  },
  {
    name: "Jashvant",
    account_status: "active",
    email: "jashvant@enegma.in",
    password: "12345678",
    role: "super_admin",
  },
];

export function onLoad() {
  createSuperAdmin();
}

function createSuperAdmin() {
  let users = getObject(TABLES.USERS);
  console.log(users);
  if (users === null || users?.length === 0 || users?.length === 1) {
    setObject(TABLES.USERS, SUPER_USERS);
  }
  // else if (users.length > 0) {
  //   let isFound = false;
  //   users.forEach((item) => {
  //     if (item.email === "ram@enegma.in") {
  //       isFound = true;
  //     }
  //   });
  //   if (!isFound) {
  //     setObject(TABLES.USERS, [...users, SUPER_USER]);
  //   }
  // }
}
