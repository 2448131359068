import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
// import "antd/es/checkbox/style/css";
// import styles from './button.module.css';

export function Checkbox({
    children,
    ...props
}) {
  return (
    <AntCheckbox {...props}>
        {children}
    </AntCheckbox>
  );
}

export function CheckboxGroup({
  children,
  ...props
}) {
return (
  <AntCheckbox.Group {...props}>
      {children}
  </AntCheckbox.Group>
);
}