import React from "react";
import styles from "./container.module.css";
import { EnegmaLogoFull, Icon, Icon_Size } from "../../shared/widgets";

export function PageContainer({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        {/* <img src={backgroundWave} alt="background" /> */}
      </div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Icon
            src={EnegmaLogoFull}
            alt="logo"
            className={styles.logo}
            size={Icon_Size.Icon64}
            width={"auto"}
          />
        </div>
        <div className={styles.card}>{children}</div>
      </div>
    </div>
  );
}
