import React, { useEffect, useState } from "react";
import { useHistory, Route } from "react-router-dom";
import { Spin } from "../shared/widgets";
import { LazyLoad } from "../shared/components";

import styles from "./styles.module.css";
import { clear } from "../shared/utils";
import { UserService } from "../shared/service";

export function PrivateRoute() {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(true);

  useEffect(() => {
    onHistoryChange();
  }, [history]);

  const onHistoryChange = () => {
    // DUMMY REQUEST TO CHECK TOKEN STATUS
    UserService.getMembers(0, 0)
      .then(() => setIsAuth(true))
      .catch((err) => {
        console.log(err, "Logging out as token not found...");
        clear();
        setIsAuth(false);
        history.push("/login");
      });
  };
  return (
    <>
      {isAuth ? (
        <>
          <Route path="/admin">
            <LazyLoad
              component={() => import("../admin")}
              componentName={"AdminDashboard"}
            />
          </Route>
          <Route path="/account">
            <LazyLoad
              component={() => import("../account")}
              componentName={"AccountPage"}
            />
          </Route>
          <Route path="/suppliers">
            <LazyLoad
              component={() => import("../suppliers")}
              componentName={"AdminSupplier"}
            />
          </Route>
          <Route path="/explorer">
            <LazyLoad
              component={() => import("../explorer")}
              componentName={"ExplorerPage"}
            />
          </Route>
          <Route path="/mytasks">
            <LazyLoad
              component={() => import("../my_tasks")}
              componentName={"MyTasksPage"}
            />
          </Route>
          <Route path="/notifications">
            <LazyLoad
              component={() => import("../inbox")}
              componentName={"InboxPage"}
            />
          </Route>
          <Route path="/flow/:flow_id">
            <LazyLoad
              component={() => import("../flow")}
              componentName={"FlowPage"}
            />
          </Route>
          <Route path="/flow/:flow_id/:item_id">
            <LazyLoad
              component={() => import("../flow")}
              componentName={"FlowPage"}
            />
          </Route>
          <Route path="/home">
            <LazyLoad
              component={() => import("../home")}
              componentName={"HomePage"}
            />
          </Route>
          <Route path="/accountProvision">
            <LazyLoad
              component={() => import("../account_provision")}
              componentName={"AccountProvisionPage"}
            />
          </Route>
          <Route path="/" exact>
            <RedirectToHomePage />
          </Route>
        </>
      ) : (
        <div className={styles.loading}>
          <span>
            <Spin />
          </span>
        </div>
      )}
    </>
  );
}

function RedirectToHomePage() {
  const history = useHistory();

  const onLoad = () => {
    // call apis to load and initialize session
    history.push("/home");
    //on error
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Loading</div>;
}
