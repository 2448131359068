import React from "react";
import {
  Input as AntInput,
  InputNumber as AntInputNumber,
  ConfigProvider,
} from "antd";

import { Select, SelectItem } from "../select";

// import "antd/es/input/style/css";
// import styles from "./input.module.css";
const { TextArea, Password, Search } = AntInput;

export function Input({ ...props }) {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            hoverBorderColor: "rgba(127, 86, 217, 0.5)",
            activeBorderColor: "rgba(127, 86, 217, 0.8)",
            activeShadow: "rgba(127, 86, 217, 0.4)",
          },
        },
      }}
    >
      <AntInput {...props} />
    </ConfigProvider>
  );
}

export { TextArea, Password, Search };

export function Number({ ...props }) {
  return <AntInputNumber {...props} />;
}

export function Currency({ ...props }) {
  return (
    <AntInputNumber
      {...props}
      // onChange={(e) => onChange(e + " USD")}
      addonAfter={CurrencyOptions}
    />
  );
}

const CurrencyOptions = (
  <Select
    defaultValue="USD"
    style={{
      width: 60,
    }}
  >
    <SelectItem value="USD">$</SelectItem>
    {/* <SelectItem value="EUR">€</SelectItem>
    <SelectItem value="GBP">£</SelectItem>
    <SelectItem value="CNY">¥</SelectItem> */}
  </Select>
);
