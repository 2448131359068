import React from 'react';
import { Alert as AntAlert } from 'antd';
// import "antd/es/alert/style/css";
// import styles from './button.module.css';

export function Alert({
    ...props
}) {
  return (
    <AntAlert {...props} />
  );
}
