
import { create } from 'zustand'
// import { useShallow } from 'zustand/react/shallow'

const store = {
  currentUser: null
};

const useUserStore = create((set) => ({
  ...store,
  setCurrentUser: (user) => set(() => ({ currentUser: user })),
  reset: () => set({ ...store }),
}))


export { useUserStore };
