import { customAlphabet } from "nanoid";

export * from "./storage";

export const onSearchData = () => {};

export function generateId(str = "") {
  const nanoid = customAlphabet(
    "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    10
  );

  return str.replace(/[^A-Z0-9]/gi, "_") + nanoid();
}


export { FIELD_TYPE, FIELD_KEYS, FIELD_ICONS, OTHER_FIELDS, FIELD_DETAILS } from "./fields"