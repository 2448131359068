import { ItemService } from "../service";
import { useFormStore } from "../store";

export function getFormData(id, itemId) {
  const setFormData = useFormStore.getState().setFormData;
  ItemService.getItem(id, itemId)
    .then((response) => {
      if (response) {
        setFormData(response);
      }
    })
    .catch((err) => console.error(err.message));
}
