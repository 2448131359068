export const Roles = {
  add_users: "add_users",
  edit_users: "edit_users",
  delete_users: "delete_users",
};

export const All_Roles = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
};

export const ROLES_MAPPED = {
  super_admin: {
    ...Roles,
  },
  user: {},
};
