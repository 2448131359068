import React from "react";
import { Tabs as AntTabs, ConfigProvider } from "antd";
// import "antd/es/tabs/style/css";

import styles from "./tabs.module.css";

// const { TabPane as } = AntTabs;

export function Tabs({ ...props }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemHoverColor: "rgba(127, 86, 217, 1)",
          },
        },
      }}
    >
      <AntTabs {...props} />
    </ConfigProvider>
  );
}

export function TabPane({ ...props }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemHoverColor: "rgba(127, 86, 217, 1)",
          },
        },
      }}
    >
      <AntTabs.TabPane {...props} />
    </ConfigProvider>
  );
}

export function PillTabs({ className, ...props }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemHoverColor: "rgba(127, 86, 217, 1)",
          },
        },
      }}
    >
      <AntTabs className={`${styles.pillTabs} ${className || ""}`} {...props} />
    </ConfigProvider>
  );
}
