import React from "react";
import { Upload as AntUpload } from "antd";
// import "antd/es/tooltip/style/css";

// import styles from './button.module.css';

const { Dragger } = AntUpload;

export function Upload({ ...props }) {
  return <AntUpload {...props} />;
}

export { Dragger };
