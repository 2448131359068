import { ItemService } from "../service";
import { useFlowStore, useFormStore } from "../store";
import { getFormData } from "./form";

export function getFlowData(id) {
  const setFlowData = useFlowStore.getState().setFlowData;
  ItemService.listItems(id)
    .then((res) => {
      if (res) {
        setFlowData(res);
      }
    })
    .catch((err) => console.error(err.message));
}

export function getFlowPosition(id) {
  const setPositionData = useFlowStore.getState().setPositionData;
  ItemService.listItems(id)
    .then((data) => {
      if (data) {
        setPositionData(data);
      }
    })
    .catch((err) => console.error(err.message));
}

//fix this one to match new api or create new api
export function moveCard(id, card, toStep) {
  let backupData = useFormStore.getState().formData;
  let phases = useFlowStore.getState().phases;
  let phasesData = useFlowStore.getState().phasesData;
  console.log(id, card, toStep, phases, phasesData);
  let updated_positions = [];
  //remove element from current lane
  let indexOfcard = phasesData[card.step].indexOf(card._id);
  console.log(indexOfcard);
  let fromLane = [...phasesData[card.step]];
  fromLane = fromLane.filter((item) => item !== card._id);
  let toLane = [...phasesData[toStep], card._id];
  let _updated_phased_data = {
    ...phasesData,
    [card.step]: fromLane,
    [toStep]: toLane,
  };
  phases.forEach((_phase) => {
    updated_positions = [...updated_positions, ..._updated_phased_data[_phase]];
  });
  console.log(
    _updated_phased_data,
    fromLane,
    toLane,

    updated_positions
  );
  const movedItemIndex = updated_positions.findIndex((posId) => posId === id);
  const prevItem =
    movedItemIndex > 0 ? updated_positions[movedItemIndex - 1] : undefined;
  const nextItem =
    movedItemIndex < updated_positions.length - 1
      ? updated_positions[movedItemIndex - 1]
      : undefined;
  // ItemService.updateItemPosition(id, {
  //   _prev_item: prevItem,
  //   _next_item: nextItem,
  //   _moved_item: id,
  //   _id: id,
  // }).then((poisitonData) => {

  // });
}
