import React from "react";
import { Modal as AntModal } from "antd";
// import "antd/es/modal/style/css";
import styles from "./modal.module.css";

export function Modal({ children, maskStyle = {}, ...props }) {
  return (
    <AntModal
      maskStyle={{
        ...maskStyle,
        background: "rgba(8, 28, 61,.8)",
      }}
      {...props}
    >
      {children}
    </AntModal>
  );
}

const getConfirmModal = AntModal.confirm;
export { getConfirmModal };
