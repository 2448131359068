import React from "react";
import { Table as AntTable } from "antd";
// import "antd/es/table/style/css";
// import "antd/es/pagination/style/css";
// import styles from './button.module.css';
const { Column } = Table;
export function Table({ dataSource, columns, ...props }) {
  return <AntTable dataSource={dataSource} columns={columns} {...props} />;
}
export { Column };
