import React from "react";

import styles from "./styles.module.css";

export function FlowCard({ flow, onClick }) {
  return (
    <div className={styles.flowCard} onClick={() => onClick(flow)}>
      {flow.title}
    </div>
  );
}
