import { create } from "zustand";
// import { useShallow } from 'zustand/react/shallow'

import { generateId } from "../utils/index";

const store = {
  metadata: null,
  rows: [],
};

const useFormBuilderStore = create((set) => ({
  ...store,
  init: (data) => set(() => ({ metadata: data })),
  addField: (fieldData) => {
    let rowId = generateId("row_");
    let fieldId = generateId("field_");
    let _data = generateRowandField(rowId, fieldId, fieldData);
    console.log(fieldData, _data);
    let _prevMetadata = useFormBuilderStore.getState().metadata;
    let _metadata = {
      ..._prevMetadata,
      [_prevMetadata.id]: {
        ..._prevMetadata[_prevMetadata.id],
        rows: [..._prevMetadata[_prevMetadata.id].rows, _data.row.id],
      },
      [_data.row.id]: _data.row,
      [_data.field.id]: _data.field,
    };
    set(() => ({
      metadata: _metadata,
      rows: _metadata[_metadata.id].rows,
    }));
  },
  removeField: () => {},
  updateField: () => {},
  rearrangeRow: () => {},
  onSave: () => {},
  reset: () => set({ ...store }),
}));

export { useFormBuilderStore };

function generateRowandField(rowId, fieldId, field) {
  return {
    row: {
      id: rowId,
      type: "field",
      field_id: fieldId,
    },
    field: {
      id: fieldId,
      ...field,
    },
  };
}
