import { create } from "zustand";
import { WorkflowService } from "../service";
import { showError } from "../widgets";
import { produce } from "immer";
import { generateId } from "../utils";
import { useAccountStore } from "./account.store";

const store = {
  flowData: null,
  status: null,
};

const useFlowBuilderStore = create((set) => ({
  ...store,
  init: () => {
    useAccountStore.getState().setLoading(true);
    WorkflowService.getDraftMetadata("purchase_order")
      .then((data) => {
        const metadata = data;
        set(() => ({ flowData: metadata, status: "draft" }));
      })
      .catch((err) => showError(err.message))
      .finally(() => useAccountStore.getState().setLoading(false));
  },
  addPhase: (phaseIndex) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        const {
          phase: phaseData,
          deadline: deadlineData,
          assignment: assignmentData,
        } = generatePhase();
        if (phaseIndex > -1) {
          _flowData.workflow.phases.splice(phaseIndex + 1, 0, phaseData.id);
        } else {
          _flowData.workflow.phases.push(phaseData.id);
        }
        _flowData[phaseData.id] = phaseData;
        _flowData[deadlineData.id] = deadlineData;
        _flowData[assignmentData.id] = assignmentData;
        state.flowData = _flowData;
      })
    );
    WorkflowService.saveDraftMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  removePhase: (phaseId) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        if (_flowData[phaseId].rows.length > 0) {
          _flowData[phaseId].rows.map((row) => {
            delete _flowData[_flowData[_flowData[row]["columns"][0]]["field"]];
            delete _flowData[_flowData[row]["columns"][0]];
            delete _flowData[row];
          });
        }
        delete _flowData[phaseId];

        const phaseIndex = _flowData.workflow.phases.findIndex(
          (phase) => phase === phaseId
        );
        _flowData.workflow.phases.splice(phaseIndex, 1);
        state.flowData = _flowData;
      })
    );
    WorkflowService.saveDraftMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  renamePhase: (phaseId, phaseName) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        _flowData[phaseId].name = phaseName;
        state.flowData = _flowData;
      })
    );
    WorkflowService.saveDraftMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  addAssignee: (phaseId, assignee) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        _flowData[_flowData[phaseId].assignment].value.push(assignee);
        state.flowData = _flowData;
      })
    );
    WorkflowService.saveDraftMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  removeAssignee: (phaseId, assigneeId) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        const valIndex = _flowData[
          _flowData[phaseId].assignment
        ].value.findIndex((assignee) => assignee.id == assigneeId);
        _flowData[_flowData[phaseId].assignment].value.splice(valIndex, 1);
        state.flowData = _flowData;
      })
    );
    WorkflowService.saveDraftMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  addDeadline: (phaseId, deadline, unit) => {
    set(
      produce((state) => {
        const _flowData = state.flowData;
        _flowData[_flowData[phaseId].duedate_rule].value = deadline;
        _flowData[_flowData[phaseId].duedate_rule].unit = unit;
        state.flowData = _flowData;
      })
    );
    WorkflowService.saveDraftMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({ status: "draft" }));
      })
      .catch((err) => showError(err.message));
  },
  onPublish: () => {
    WorkflowService.saveLiveMetadata(
      "purchase_order",
      useFlowBuilderStore.getState().flowData
    )
      .then(() => {
        set(() => ({
          status: "live",
        }));
      })
      .catch((err) => showError(err.message));
  },
  reset: () => set({ ...store }),
}));

function generatePhase() {
  const phaseId = "ph_" + generateId();
  const deadlineId = "duedate_" + generateId();
  const assignmentId = "assignment_" + generateId();
  const phaseObj = {
    id: phaseId,
    rows: [],
    duedate_rule: deadlineId,
    assignment: assignmentId,
    name: "Test",
  };
  const deadlineObj = {
    id: deadlineId,
    type: "duedate",
    duedatetype: "static",
  };
  const assignmentObj = {
    id: assignmentId,
    type: "Assignment",
    assignmenttype: "static",
    value: [],
  };

  return {
    phase: phaseObj,
    deadline: deadlineObj,
    assignment: assignmentObj,
  };
}

export { useFlowBuilderStore };
