import React from "react";
import { Collapse as AntCollapse } from "antd";
// import "antd/es/collapse/style/css";
// import styles from './button.module.css';

export function Collapse({ children, ...props }) {
  return <AntCollapse {...props}>{children}</AntCollapse>;
}

const { Panel } = AntCollapse;

export { Panel as CollapsePanel };
