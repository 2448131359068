import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  HomeIcon,
  HomeActiveIcon,
  ExplorerIcon,
  ExplorerActiveIcon,
  NotificationIcon,
  TaskIcon,
  Icon,
  Menu,
  SupplierMenuIcon
  // MenuItem,
} from "../../widgets";

import { ALL_FLOWS } from "../../../data";

import styles from "./styles.module.css";
import { Layout } from "antd";
const { Sider, Content } = Layout;

const HomeNav = [
  {
    name: "Home",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon,
    id: 1,
    route: "/home",
  },
  {
    name: "My tasks",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon,
    id: 2,
    route: "/mytasks",
  },
  {
    name: "Notifications",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon,
    id: 3,
    route: "/notifications",
  },
];

const TABS = [
  // {
  //   name: "Account Provision",
  //   icon: ProvisionIcon,
  //   activeIcon: ProvisionActiveIcon,
  //   id: 5,
  //   route: "/accountProvision",
  // },
  {
    name: "Explorer",
    icon: ExplorerIcon,
    activeIcon: ExplorerActiveIcon,
    id: 4,
    route: "/explorer",
  },
];

const SIDE_NAV_ITEMS = [
  {
    key: "home",
    label: "Home",
    icon: <Icon src={HomeIcon} />,
  },
  {
    key: "mytasks",
    label: "My Tasks",
    icon: <Icon src={TaskIcon} />,
  },
  {
    key: "notifications",
    label: "Notifications",
    icon: <Icon src={NotificationIcon} />,
  },
  {
    type: "divider",
  },
  // {
  //   key: "flow",
  //   label: "Requests",
  //   children: [],
  // },
  {
    key: 'flow',
    label: 'Requests',
    type: 'group',
    children: [
    ],
  },
  {
    type: "divider",
  },
  {
    key: "explorer",
    label: "Explorer",
    icon: <Icon src={ExplorerIcon} />,
  },
  {
    type: "divider",
  },
  {
    key: "suppliers",
    label: "Supplier",
    icon: <Icon src={SupplierMenuIcon} />,
  },
  {
    type: "divider",
  },
];

export function SideMenu() {
  const [paths, setPaths] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [sideNavItems, setSideNavItems] = useState([]);

  const history = useHistory();

  useEffect(() => {
    onLoad();
    checkTab();
    let _data = [];
    let _paths = {};
    let _flow_data = [];
    ALL_FLOWS.forEach((_flow) => {
      _flow_data.push({
        key: _flow.id,
        label: _flow.title,
        icon: _flow.icon,
      });
      _paths[_flow.id] = `flow/${_flow.id}`;
    });
    SIDE_NAV_ITEMS.forEach((nav_item) => {
      if (nav_item.key === "flow") {
        _data.push({
          ...nav_item,
          children: _flow_data,
        });
      } else {
        _data.push(nav_item);
      }
    });
    setSideNavItems(_data);
    setPaths(_paths);
  }, []);

  const onLoad = (values) => { };

  const onClick = (e) => {
    console.log(e);
    // if (e.keyPath.length === 1) {
    //   history.push("/" + e.key);
    // } else {
    //   history.push("/" + e.keyPath[1] + "/" + e.keyPath[0]);
    // }
    if(paths[e.key]) {
      history.push("/" + paths[e.key]);
    } else {
      history.push("/" + e.key);
    }

    setSelectedKeys(e.keyPath);
  };

  const checkTab = () => {
    let tab = getParamFromURL(1);
    let flowId = getParamFromURL(2);

    console.log(tab);
    // setDefaultTab("/" + tab);
    if (tab !== "flow") {
      setSelectedKeys([tab]);
    } else if (tab === "flow") {
      setSelectedKeys([flowId, tab]);
    }
  };

  const [collapsed, setCollapsed] = useState(true);
  // const [openKeys, setOpenKeys] = useState([]);


  return (
    <Layout style={{ height: "100%" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={240}
        className={styles.siteSiderOverlay}
        collapsedWidth={44}
        style={{ backgroundColor: "#222B39 " }}
      >
        <Menu
          theme="dark"
          onClick={onClick}
          defaultSelectedKeys={["1"]}
          // defaultOpenKeys={openKeys}
          mode="inline"
          items={sideNavItems}
          selectedKeys={selectedKeys}
          style={{ backgroundColor: "#222B39 " }}
        />
      </Sider>
    </Layout>
  );

  // return (
  //   <div className={styles.sideMenu}>
  //     {/* <div>
  //       {HomeNav.map((item) => {
  //         return (
  //           <span
  //             className={`${styles.tabIcon} ${
  //               defaultTab === item.route ? styles.activeTab : ""
  //             }`}
  //             onClick={() => {
  //               history.push(item.route);
  //             }}
  //           >
  //             <span className={`${styles.icon}`}>
  //               <Icon
  //                 src={defaultTab === item.route ? item.activeIcon : item.icon}
  //                 size={Icon_Size.Icon24}
  //               />
  //             </span>
  //             <span>{item.name}</span>
  //           </span>
  //         );
  //       })}
  //     </div>
  //     <div>
  //       <span>My requests</span>
  //     </div> */}
  //     {/* {TABS.map((item) => {
  //       return (
  //         <span
  //           className={`${styles.tabIcon} ${
  //             defaultTab === item.route ? styles.activeTab : ""
  //           }`}
  //           onClick={() => {
  //             history.push(item.route);
  //           }}
  //         >
  //           <span className={`${styles.icon}`}>
  //             <Icon
  //               src={defaultTab === item.route ? item.activeIcon : item.icon}
  //               size={Icon_Size.Icon24}
  //             />
  //           </span>
  //           <span>{item.name}</span>
  //         </span>
  //       );
  //     })} */}
  //     <Menu
  //       onClick={onClick}
  //       style={{ width: "17rem" }}
  //       defaultSelectedKeys={["1"]}
  //       defaultOpenKeys={["flow"]}
  //       mode="inline"
  //       items={sideNavItems}
  //       selectedKeys={selectedKeys}
  //     />
  //   </div>
  // );
}

export function getParamFromURL(param) {
  let pathname = window.location.pathname;
  let data = pathname.split("/");
  return data[param] || "";
}
