import { create } from "zustand";

// Define the store
const useSupplierStore = create((set) => ({
  // Initial state
  suppliers: [],

  setSuppliers: (supplierList) => {
    console.log("fsdfsdfsdsd", supplierList);

    set(() => ({
      suppliers: supplierList,
    }));
  },

  // Method to add a supplier
  addSupplier: (supplier) =>
    set((state) => ({
      suppliers: [...state.suppliers, supplier],
    })),

  // Method to delete a supplier by ID
  deleteSupplier: (supplierId) =>
    set((state) => ({
      suppliers: state.suppliers.filter(
        (supplier) => supplier.supplier_id !== supplierId
      ),
    })),

  addBulkSupplier: (supplierList) =>
    set((state) => {
      const newSuppliers = [...state.suppliers, ...supplierList];
      return { suppliers: newSuppliers };
    }),

  // Method to update a supplier by ID
  updateSupplier: (updatedSupplier) =>
    set((state) => ({
      suppliers: state.suppliers.map((supplier) =>
        supplier.supplier_id === updatedSupplier.supplier_id
          ? { ...supplier, ...updatedSupplier }
          : supplier
      ),
    })),
}));

export { useSupplierStore };
