import React from "react";
import { ConfigProvider, Button as AntButton } from "antd";
import { Icon } from "../icon";
// import 'antd/es/button/style/css';
import styles from "./button.module.css";

export const BUTTON_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "default",
  LINK: "link",
  TEXT: "text",
  DASHED: "dashed",
};

export const BUTTON_SIZE = {
  LARGE: "large",
  MIDDLE: "middle",
  SMALL: "small",
};

// CONFIGURING COMPONENTS WITH ANTD CONFIGURE (BETTER APPROACH)
export function AntdButton({
  children,
  type = BUTTON_TYPE.SECONDARY,
  size = BUTTON_SIZE.MIDDLE,
  block,
  ...props
}) {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#00b96b",
          borderRadius: 2,

          // Alias Token
          colorBgContainer: "#f6ffed",
        },
      }}
    >
      <AntButton type={type} size={size} block={block} {...props}>
        {children}
      </AntButton>
    </ConfigProvider>
  );
}

export function Button({
  children,
  type = BUTTON_TYPE.SECONDARY,
  size = BUTTON_SIZE.MIDDLE,
  block,
  ...props
}) {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        token: {
          colorPrimaryHover: "rgba(127, 86, 217, 0.8)",
          colorPrimaryActive: "rgba(127, 86, 217, 0.8)",
        },
      }}
    >
      <AntButton type={type} size={size} block={block} {...props}>
        {children}
      </AntButton>
    </ConfigProvider>
  );
}

export function ButtonWtIcon({
  children,
  type = BUTTON_TYPE.SECONDARY,
  size = BUTTON_SIZE.MIDDLE,
  className,
  block,
  icon,
  ...props
}) {
  return (
    <ConfigProvider wave={{ disabled: true }}>
      <AntButton
        type={type}
        size={size}
        className={`${styles.iconButton} ${className || ""}`}
        block={block}
        {...props}
      >
        <Icon className={`${styles.buttonIcon}`} src={icon} />
        <p>{children}</p>
      </AntButton>
    </ConfigProvider>
  );
}
