import React, { useEffect, useState } from "react";
import { Table } from "../../widgets";
import { GridHeader } from "../gridheader";
import { onSearchData } from "../../utils";

import styles from "./styles.module.css";

export function GridWithHeader({
  headerText,
  onCreate,
  onTableChange,
  loading,
  createButtonContent,
  children,
  dataSource,
  columns,
  rowSelection,
  disableText,
  scroll,
  pagination = { pageSize: 10 },
}) {
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [searchText, setSearchText] = useState("");

  const onSearch = (search, orginalData) => {
    setSearchText(search);
    let filteredData = [];
    if (search.length > 0) {
      filteredData = onSearchData(search, orginalData || dataCopy, columns);
    } else {
      filteredData = [...dataCopy];
    }
    setData(filteredData);
  };

  useEffect(() => {
    setDataCopy([...dataSource]);
    if (searchText.length > 0) {
      onSearch(searchText, dataSource);
    } else {
      setData([...dataSource]);
    }
  }, [dataSource]);

  return (
    <div className={styles.header}>
      <GridHeader
        headerText={headerText}
        onSearch={onSearch}
        createButtonContent={createButtonContent}
        onCreate={onCreate}
        disableText={disableText}
      >
        {children && children}
      </GridHeader>
      <Table
        dataSource={data}
        columns={columns}
        rowSelection={rowSelection}
        pagination={pagination}
        scroll={scroll}
        onChange={onTableChange}
        loading={loading}
      />
    </div>
  );
}
