import { post, get, put, deleteReq } from "./service";

const category = "procurement";

export const ItemService = {
  createLineItem(table_id, item_id, connected_model_id) {
    return post(`/${category}/${table_id}/${item_id}/connectedmodel/${connected_model_id}`, { body: {} });
  },
  deleteLineItem(table_id, item_id, connected_model_id, connected_model_row_id ){
    // return post(`/${category}/${table_id}/${item_id}/connectedmodel/${connected_model_id}`, { body: {} });
  },
  createItem(table_id, data) {
    return post(`/${category}/${table_id}`, { body: data });
  },
  updateItem(table_id = "purchase_request", item_id = "1", data) {
    return post(`/${category}/${table_id}/${item_id}`, { body: data });
  },
  updateLineItemBatch(table_id = "purchase_request", item_id = "1", line_item, data) {
    return post(`/${category}/${table_id}/${item_id}/connectedmodel/${line_item}/batch/update`, { body: data });
  },
  updateItemPosition(table_id = "purchase_request", item_id = "1", data) {
    return post(`/${category}/${table_id}/${item_id}/move`, { body: data });
  },
  getItem(table_id = "purchase_request", item_id = "1") {
    return get(`/${category}/${table_id}/${item_id}`);
  },
  listItems(table_id = "purchase_request") {
    return get(`/${category}/${table_id}/list`);
  },
  bill(table_id = "purchase_request", data) {
    return post(`/${category}/bills/${table_id}`, {
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });
  },
  listItemPosition(table_id = "purchase_request") {
    return get(`/${category}/kanban_position/${table_id}`);
  },
  getSupplierList(flowId, itemId) {
    return get(`/${category}/${flowId}/${itemId}/dropdown/suppliers`);
  },
  getSupplierData(supplierId) {
    return get(`/${category}/suppliers/${supplierId}`);
  },
  sendRFQ(table_id = "purchase_request", item_id = "1", data = {}) {
    return post(`/${category}/${table_id}/${item_id}/rfq`, {
      body: data,
    });
  },
  getLiveMetadata(table_id = "purchase_order") {
    return get(`/metadata/live/${table_id}`);
  },
  updateSupplierForm(
    table_id = "purchase_request",
    item_id = "1",
    supplier_id = 1,
    data
  ) {
    return post(
      `/${category}/${table_id}/${item_id}/supplier/${supplier_id}/receive_quote`,
      { body: data }
    );
  },
  releasePO(
    table_id = "purchase_request",
    item_id = "1",
    quotation_id = 1,
    data = {}
  ) {
    return post(
      `/${category}/${table_id}/${item_id}/supplier/${quotation_id}/release_po`,
      {
        body: data,
      }
    );
  },
  analyzeRFQ(table_id = "purchase_request", item_id = "1", data = {}) {
    return post(`/${category}/${table_id}/${item_id}/rfq/analyze`, {
      body: data,
    });
  },
  threewayMatching(item_id = "PKOmXJtGhxQTCG9gz0Y2ppOw", data = {}) {
    return post(`/${category}/three_way_matching/${item_id}`, {
      body: data,
    });
  },
};
